
@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
 @apply overflow-x-hidden;
}


@font-face {
    font-family: 'Syne';
    src: url('./fonts/Syne/Syne-VariableFont_wght.ttf');
    font-weight: normal;
    font-style: normal;
}


.syne {
  font-family: "Syne", sans-serif;
  font-optical-sizing: auto;
  font-weight: 40px;
  font-style: normal;
}

@font-face {
  font-family: 'Questrial';
  src: url('./fonts/Questrial/Questrial-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}


.questrial-regular {
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
}
